export const FEATURE_FLAGS = {
    pg358ShowLandingPopUp: false,
    pg415AddPricingModel: false,
    pg418AddmarketPosition: false,
    pg420ShowUniqueness: false,
    pg464EnableCaching: false,
    pg599ShowToolsInCached: false,
    pg606ShowLandingPage: false,
    pg669AddMatchBanners: false,
    enableBotTyping: false,
};
