import { AppConfiguration, CachedUTM, Features } from './configuration.types';
import Experiment from '../experiments/experiment';
import { LandingPopupConfig } from '@components/LandingPopup/LandingPopup.types';
import { SignOutConfig } from '@components/screens/SignOut/SignOut.types';
import { SignUpConfig } from '@components/screens/SignUp/SignUp.types';
import { LoaderAnimationConfig } from '@components/LoaderAnimation/LoaderAnimation.types';
import { EmptyDataConfig } from '@components/EmptyData/EmptyData.types';
import { ChatConfig } from '@components/Chat/Chat.types';
import { ComparisonTableConfig } from '@components/Table/table.types';
import { LeaveEmailBoxConfig } from '@components/screens/Screen/LeaveEmailBox/LeaveEmailBox.types';
import { HomeScreenConfig } from '@components/screens/HomeScreen/HomeScreen.types';
import { BannerConfig } from '@components/screens/Screen/Banner/Banner.types';
import Logger from '@logging/logger';
import { URLFeatureFlags } from '@core/URLHandler';
import { LandingPageConfig } from '@components/LandingPage/LandingPage.types';
import Contentful from '@content_management/contentful';

export default class Config {
    static isDevMode: boolean = process.env.REACT_APP_DEV_MODE === 'true';
    static allConfigurations: AppConfiguration[] = [];
    static appConfiguration?: AppConfiguration;
    static _allUTMs: CachedUTM[] = [];
    static features: Features;
    static banner: BannerConfig;
    static homeScreen: HomeScreenConfig;
    static leaveEmailBox: LeaveEmailBoxConfig;
    static comparisonTable: ComparisonTableConfig;
    static emptyData: EmptyDataConfig;
    static chat: ChatConfig;
    static loaderAnimation: LoaderAnimationConfig;
    static signUp: SignUpConfig;
    static signOut: SignOutConfig;
    static landingPopup: LandingPopupConfig;
    static landingPage: LandingPageConfig;

    static async init() {
        try {
            Config.allConfigurations = Contentful.allConfigurations;
            Config._allUTMs = Contentful.allUTMs;
            Config.appConfiguration = Experiment.configuration;
            if (Config.appConfiguration) {
                (Object.keys(Config.appConfiguration) as Array<keyof AppConfiguration>)
                    .filter((key) => key !== 'name')
                    .forEach((key) => {
                        (Config as any)[key] = Config.appConfiguration?.[key] ?? {};
                    });
            }
        } catch (e) {
            Logger.error('Failed to load configuration', e);
        }
    }

    public static overrideFeatureFlags(urlFeatures: URLFeatureFlags) {
        Config.features = {
            ...Config.features,
            ...Object.entries(urlFeatures)
                .filter(([_, v]) => v !== undefined)
                .reduce((o, [k, v]) => ({ ...o, [k]: v }), {} as Features),
        };

        Logger.debug('Feature flags overridden:', {
            original: Config.appConfiguration?.features,
            urlOverrides: urlFeatures,
            final: Config.features,
        });
    }

    public static get allUTMs() {
        return Config._allUTMs;
    }
}
