/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Agent API
 * API for agent interactions
 * OpenAPI spec version: 1.0
 */
import type {
    ClientLogInterface,
    FirstPromptInterface,
    FirstPromptResponse,
    GetToolsInterface,
    GetToolsResponse,
    InitServerSessionResponse,
    InvalidRequestResponse,
    MixpanelStatusInterface,
    NPromptInterface,
    NPromptResponse,
    ServerSessionInterface,
} from './generated.schemas';
import { customInstance } from '../axios';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAgentAPI = () => {
    const postClientSideLogResource = (
        clientLogInterface: ClientLogInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<void>(
            {
                url: `/api/client_side_log`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: clientLogInterface,
            },
            options
        );
    };

    const postFirstPromptResource = (
        firstPromptInterface: FirstPromptInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<FirstPromptResponse | InvalidRequestResponse>(
            {
                url: `/api/first_prompt`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: firstPromptInterface,
            },
            options
        );
    };

    const postGetToolsResource = (
        getToolsInterface: GetToolsInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<GetToolsResponse>(
            {
                url: `/api/get_tools`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: getToolsInterface,
            },
            options
        );
    };

    const postInitServerSessionResource = (
        serverSessionInterface: ServerSessionInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<InitServerSessionResponse>(
            {
                url: `/api/init_server_session`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: serverSessionInterface,
            },
            options
        );
    };

    const postLogMixpanelStatusResource = (
        mixpanelStatusInterface: MixpanelStatusInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<void>(
            {
                url: `/api/mixpanel_status`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: mixpanelStatusInterface,
            },
            options
        );
    };

    const postNPromptResource = (
        nPromptInterface: NPromptInterface,
        options?: SecondParameter<typeof customInstance>
    ) => {
        return customInstance<NPromptResponse>(
            {
                url: `/api/n_prompt`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: nPromptInterface,
            },
            options
        );
    };

    return {
        postClientSideLogResource,
        postFirstPromptResource,
        postGetToolsResource,
        postInitServerSessionResource,
        postLogMixpanelStatusResource,
        postNPromptResource,
    };
};
export type PostClientSideLogResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postClientSideLogResource']>>
>;
export type PostFirstPromptResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postFirstPromptResource']>>
>;
export type PostGetToolsResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postGetToolsResource']>>
>;
export type PostInitServerSessionResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postInitServerSessionResource']>>
>;
export type PostLogMixpanelStatusResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postLogMixpanelStatusResource']>>
>;
export type PostNPromptResourceResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof getAgentAPI>['postNPromptResource']>>
>;
