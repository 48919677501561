import { FAKE_NAME_PREFIX } from '@config/constants';
import { en, Faker } from '@faker-js/faker';

export default class NameGenerator {
    static faker: Faker;

    private static initFaker() {
        if (!NameGenerator.faker) {
            NameGenerator.faker = new Faker({ locale: en });
        }
    }

    public static generate(): string {
        NameGenerator.initFaker();
        return FAKE_NAME_PREFIX + NameGenerator.faker.person.fullName();
    }
}
