import axios, { AxiosRequestConfig } from 'axios';
import Auth from 'src/auth/Auth';
import { collectUserData } from '@logging/UserDataCollector';
import { API_TIMEOUT } from '@config/constants';

const instance = axios.create({
    baseURL: '', // Base URL for all requests
    timeout: API_TIMEOUT, // Default timeout in milliseconds
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add request interceptor (optional)
instance.interceptors.request.use(
    (config) => {
        let userData = { sessionId: Auth.sessionId(), ...collectUserData() };
        config.headers['X-User-Data'] = JSON.stringify(userData);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor (optional)
instance.interceptors.response.use(
    (response) => {
        return {
            ...response,
            data: response.data.response,
            status: response.status,
        };
    },
    (error) => {
        // Any status codes outside the range of 2xx cause this function to trigger
        if (error.response) {
            throw new Error(
                'Server responded with error message: ' +
                    error.response.data.error.message +
                    '\nstatus: ' +
                    error.response.status
            );
        } else if (error.request) {
            // Request was made but no response received
            console.error('Request error:', error.request);
        } else {
            // Something else happened
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export const customInstance = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig
): Promise<{ data: T; status: number }> => {
    return instance({ ...config, ...options }) as Promise<{ data: T; status: number }>;
};
