import { BrowserAuthError, PublicClientApplication } from '@azure/msal-browser';
import { z } from 'zod';
import {
    AVATAR_STORAGE_KEY,
    MICROSOFT_GRAPH_FETCH_TOKEN_URL,
    MICROSOFT_SSO_AUTHORITY,
    OUTLOOK_MAIL_SUFFIX,
} from '@config/constants';
import Auth, { SSOProvider } from './Auth';
import Logger from '@logging/logger';

export interface MicrosoftUserData {
    name: string;
    email: string;
    photoBlob: Blob;
}

export class Microsoft {
    private static readonly SCOPES = ['openid', 'profile', 'User.Read', 'email'];
    private static readonly GRAPH_ENDPOINT = MICROSOFT_GRAPH_FETCH_TOKEN_URL;
    private static msal: PublicClientApplication;

    public static async init(clientId: string) {
        Microsoft.msal = new PublicClientApplication({
            auth: {
                clientId,
                authority: MICROSOFT_SSO_AUTHORITY,
                redirectUri: `${window.location.origin}`,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                allowRedirectInIframe: true,
                windowHashTimeout: 9000,
            },
        });

        await Microsoft.msal.initialize();
        if (window.location.hash) {
            await Microsoft.msal.handleRedirectPromise();
        }
    }

    private static async getToken(): Promise<string> {
        try {
            const result = await Microsoft.msal.loginPopup({
                scopes: Microsoft.SCOPES,
                prompt: 'select_account',
            });

            return result.accessToken;
        } catch (error) {
            if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
                return '';
            }
            throw error;
        }
    }

    public static async logIn(): Promise<MicrosoftUserData> {
        try {
            const accessToken = await Microsoft.getToken();

            const [userData, photoBlob] = await Promise.all([
                Microsoft.getUserData(accessToken),
                Microsoft.getUserPhoto(accessToken),
            ]);

            return {
                name: userData.displayName,
                email: Microsoft.chooseEmail(userData) || '',
                photoBlob: photoBlob,
                country: userData.country,
            } as MicrosoftUserData;
        } catch (error) {
            Auth.handleSSOFailure(SSOProvider.MICROSOFT, error);
            return {} as MicrosoftUserData;
        }
    }

    private static async getUserData(accessToken: string): Promise<any> {
        const response = await fetch(
            `${Microsoft.GRAPH_ENDPOINT}/me?$select=id,displayName,givenName,surname,userPrincipalName,country`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Graph API error: ${response.status}`);
        }

        return response.json();
    }

    private static async getUserPhoto(accessToken: string): Promise<Blob | undefined> {
        const timeout = 1000;
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        try {
            const response = await fetch(`${Microsoft.GRAPH_ENDPOINT}/me/photo/$value`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                signal: controller.signal,
            });
            clearTimeout(timeoutId);

            if (!response.ok) {
                return undefined;
            }

            return await response.blob();
        } catch (error) {
            if (error instanceof Error) {
                Logger.error('Error getting microsoft photo:', error.message);
            }
            return undefined;
        }
    }

    private static chooseEmail(response: any): string | undefined {
        const schema = z.string().email().endsWith(OUTLOOK_MAIL_SUFFIX);
        if (!schema.safeParse(response.mail).success) {
            return response.mail;
        }

        if (!schema.safeParse(response.userPrincipalName).success) {
            return response.userPrincipalName;
        }

        return undefined;
    }

    public static async parseLoginResponse(userData: MicrosoftUserData) {
        await Auth.cacheAndConvertImage(AVATAR_STORAGE_KEY, userData.photoBlob).then((base64) => {
            Auth.commonLogIn(
                userData.name,
                base64 || '',
                !!base64,
                userData.email,
                SSOProvider.MICROSOFT
            );
        });
    }
}
