import { createClient, EntrySkeletonType } from 'contentful';
import { ChainModifiers, ContentfulClientApi } from 'contentful/dist/types/types/client';
import { ResolveType } from './contentfulTypeResolve';
import Logger from '../logging/logger';
import { LocaleCode } from 'contentful/dist/types/types/locale';
import { Entry } from 'contentful/dist/types/types/entry';
import { _AppConfiguration, AppConfiguration } from '@config/configuration.types';
import { CachedUTM } from '@config/configuration.types';
import { ContentfulConfig } from '@config/contentful.configuration';
import { _ExperimentConfig, ExperimentConfig } from '@experiments/experiment.types';
import Config from '../config/config';

class Contentful {
    private static client: ContentfulClientApi<any>;
    public static configurations: AppConfiguration[] | null;
    private static _allConfigurations: AppConfiguration[] = [];
    private static _allUTMs: CachedUTM[] = [];
    public static cachedUTM: CachedUTM[] | null;
    private static initialized: boolean = false;
    public static experiment: ExperimentConfig | null;
    public static testConfig: AppConfiguration | null;

    static async init(spaceId: string, accessToken: string): Promise<boolean> {
        try {
            Contentful.client = createClient({
                space: spaceId,
                accessToken: accessToken,
                environment: ContentfulConfig.environment,
                host: 'cdn.contentful.com',
            });

            Contentful._allUTMs = (await Contentful.fetchCachedUTM()) || [];
            Contentful._allConfigurations = (await Contentful.fetchConfigurations()) || [];

            Contentful.experiment = await Contentful.fetchExperiment();
            Contentful.configurations = Contentful.experiment?.variants || null;
            Contentful.cachedUTM = await Contentful.fetchCachedUTM();
            if (Config.isDevMode) {
                Contentful.testConfig = await Contentful.fetchTestConfig();
            }
            Contentful.initialized = true;
        } catch (e) {
            console.error('Failed to load content from contentful: ', e);
            throw new Error(
                'Failed to load content from contentful. Env name: ' +
                    (ContentfulConfig.environment || 'Non existant')
            );
        }
        return Contentful.initialized;
    }

    public static getConfigNum(): number {
        if (Contentful.initialized && Contentful.configurations) {
            return Contentful.configurations.length;
        }
        return 0;
    }

    public static async fetchEntry<T extends EntrySkeletonType<any>>(
        entryId: string
    ): Promise<ResolveType<T['fields']> | null> {
        try {
            const entry = await Contentful.client.getEntry<T>(entryId, {
                include: ContentfulConfig.referenceIncludeDepth as any,
            });
            return Contentful.parseEntry(entry);
        } catch (error) {
            Logger.error('Error fetching from Contentful:', error);
            throw error;
        }
    }

    private static parseEntry<
        ES extends EntrySkeletonType,
        M extends ChainModifiers,
        L extends LocaleCode,
    >(entry: Entry<ES, M, L>) {
        const parseValue = (value: any): any => {
            // Handle null/undefined
            if (!value) return value;

            // Handle arrays recursively
            if (Array.isArray(value)) {
                return value.map(parseValue);
            }

            // Handle objects
            if (typeof value === 'object') {
                // Check if it's a Contentful entry with fields
                if ('fields' in value) {
                    // Recursively parse all fields
                    return Object.entries(value.fields).reduce((acc, [fieldKey, fieldValue]) => {
                        acc[fieldKey] = parseValue(fieldValue);
                        return acc;
                    }, {} as any);
                }

                // For regular objects, parse each property recursively
                return Object.entries(value).reduce((acc, [key, val]) => {
                    acc[key] = parseValue(val);
                    return acc;
                }, {} as any);
            }

            // Return primitive values as is
            return value;
        };

        // Start the parsing from the entry's fields
        return Object.entries(entry.fields).reduce((acc, [key, value]) => {
            acc[key] = parseValue(value);
            return acc;
        }, {} as any);
    }

    private static async fetchEntries(type: string) {
        return await Contentful.client.getEntries({
            content_type: type,
            include: ContentfulConfig.referenceIncludeDepth as any,
        });
    }

    private static async fetchEntriesByName(name: string, type: string) {
        return await Contentful.client.getEntries({
            query: name,
            content_type: type,
        });
    }

    private static async fetchExperiment(): Promise<ExperimentConfig | null> {
        try {
            return await Contentful.fetchEntry<_ExperimentConfig>(
                ContentfulConfig.getEntryId('experiment')
            );
        } catch (error) {
            Logger.error('Error fetching experiment from Contentful:', error);
            throw error;
        }
    }

    private static async fetchConfigurations(): Promise<AppConfiguration[] | null> {
        const configurations = await Contentful.fetchEntries(
            ContentfulConfig.getTypeId('configuration')
        );
        if (configurations !== null) {
            return configurations.items.map((item) => Contentful.parseEntry(item));
        } else {
            return null;
        }
    }

    public static async fetchConfiguration(name: string): Promise<AppConfiguration | null> {
        try {
            const configuration = await Contentful.fetchEntriesByName(name, 'configuration');
            if (configuration !== null) {
                return configuration.items.map((item) => Contentful.parseEntry(item))[0];
            } else {
                return null;
            }
        } catch (error) {
            Logger.error('Error fetching configuration from Contentful:', error);
            throw error;
        }
    }

    private static async fetchTestConfig(): Promise<AppConfiguration | null> {
        try {
            return await Contentful.fetchEntry<_AppConfiguration>(
                ContentfulConfig.getEntryId('testConfig')
            );
        } catch (error) {
            Logger.error('Error fetching test configuration from Contentful:', error);
            throw error;
        }
    }

    private static async fetchCachedUTM(): Promise<CachedUTM[] | null> {
        const cachedUtms = await Contentful.fetchEntries(ContentfulConfig.getTypeId('cachedUtm'));
        if (cachedUtms) {
            return cachedUtms.items.map((item) => Contentful.parseEntry(item));
        } else {
            return null;
        }
    }

    public static get allConfigurations() {
        return Contentful._allConfigurations;
    }

    public static get allUTMs() {
        return Contentful._allUTMs;
    }
}

export default Contentful;
