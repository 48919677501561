import { ILogger, Severity } from './logger';
import { logToServer } from '@core/Core';

class ServerLogger implements ILogger {
    public log(level: Severity, message: string, ...args: any[]): void {
        void logToServer([message, ...args].join(', '), level.toLowerCase());
    }
}

export default ServerLogger;
