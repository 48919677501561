import Logger from './logging/logger';
import { createRoot } from 'react-dom/client';
import App from '@components/App';
import { initServerSession } from '@core/Core';
import HotjarAnalytics from '@analytics/Hotjar';
import Mixpanel from '@analytics/Mixpanel';
import GoogleAnalytics from '@analytics/GoogleAnalytics';
import Contentful from '@content_management/contentful';
import Config from '@config/config';
import Auth from '@auth/Auth';
import { sendMixpanelStatus } from '@core/Core';
import ServerLogger from '@logging/serverLogger';
import URLParams from '@core/URLHandler';
import Experiment from '@experiments/experiment';
import { AnalyticsEvent, AnalyticsEventName } from '@analytics/AnalyticsEvent';

const container = document.getElementById('root');
const root = createRoot(container!);

function main() {
    URLParams.collectURLParams();
    Logger.registerLogger(new ServerLogger());

    initServerSession()
        .then(async (tokens) => {
            await Contentful.init(
                tokens?.contentfulSpaceId || '',
                tokens?.contentfulAccessToken || ''
            );
            return tokens;
        })
        .then(async (tokens) => {
            await Auth.loadUserFromLocalStorage();
            return tokens;
        })
        .then(async (tokens) => {
            if (tokens.mixpanelToken) {
                Mixpanel.init(tokens?.mixpanelToken);
            } else {
                Logger.error('Mixpanel token is not available');
                void sendMixpanelStatus('Mixpanel missing token');
            }
            GoogleAnalytics.init(tokens?.googleAnalyticsMeasurementId || '');
            HotjarAnalytics.init(tokens?.hotjarSiteId || 0);
            Auth.init(
                tokens?.googleAuthClientId || '',
                tokens?.linkedinClientId || '',
                tokens?.facebookAppId || '',
                tokens?.microsoftClientId || '',
                tokens?.microsoftTenantId || ''
            );

            await Experiment.start();
            await Config.init();
            Config.overrideFeatureFlags(URLParams.featureFlags);
        })
        .then(() => {
            GoogleAnalytics.identify();
            Mixpanel.startSession(performance.getEntriesByType('navigation')[0]?.duration || 0);
            HotjarAnalytics.identify();
            void sendMixpanelStatus('Mixpanel initialized');
        })
        .then(() => {
            trackSessionClose();
        })
        .then(() => {
            root.render(<App />);
        })
        .catch((error) => {
            Logger.error(error.message);
        });
}

function trackSessionClose() {
    let eventSent = false;

    function sendCloseEvent() {
        if (!eventSent) {
            eventSent = true;

            const sessionDuration = Math.floor((Date.now() - performance.timeOrigin) / 1000);

            AnalyticsEvent.create(AnalyticsEventName.SESSION_CLOSE)
                .set('$duration', sessionDuration)
                .mixpanelTrack()
                .googleAnalyticsTrack();
        }
    }

    window.addEventListener('beforeunload', (event) => {
        sendCloseEvent();
    });

    window.addEventListener('unload', (event) => {
        sendCloseEvent();
    });
}

main();
