import { basicApi } from '@utils/ServerUtils';
import URLParams from './URLHandler';
import { tool } from '@components/screens/SplitScreen/SplitScreen';
import Auth from 'src/auth/Auth';
import { getAgentAPI } from 'src/utils/generated/generated';

import {
    FirstPromptResponse,
    GetToolsResponse,
    InitServerSessionResponse,
    InvalidRequestResponse,
    NPromptResponse,
    Tool,
} from 'src/utils/generated/generated.schemas';

const api = getAgentAPI();

export async function firstPrompt(
    prompt: string
): Promise<FirstPromptResponse | InvalidRequestResponse> {
    const apiResponse = await api.postFirstPromptResource({
        prompt,
        utm_params: URLParams.utmParams,
    });

    if (apiResponse.status === 200) {
        return apiResponse.data as FirstPromptResponse;
    } else if (apiResponse.status === 201) {
        return apiResponse.data as InvalidRequestResponse;
    }
    throw new Error(apiResponse.data.toString());
}

export async function NPrompt(type: string, user_input: string): Promise<NPromptResponse> {
    const apiResponse = await api.postNPromptResource({ type, user_input });
    return apiResponse.data as NPromptResponse;
}

export async function getTools(
    tools: tool[],
    updateOnly: boolean = false,
    signal?: AbortSignal
): Promise<Tool[]> {
    const apiResponse = await api.postGetToolsResource({
        tools: tools.map((tool) => ({
            tool_name: tool.toolName,
            features: tool.features || [],
        })),
        update_only: updateOnly,
    });
    console.log(apiResponse);
    return (apiResponse.data as GetToolsResponse).tools;
}

export async function sendMixpanelStatus(status: string) {
    await api.postLogMixpanelStatusResource({ status });
}

export async function initServerSession(): Promise<InitServerSessionResponse> {
    try {
        Auth.sessionId(true);
        const data = { urlFeatureFlags: URLParams.featureFlags, urlUtmParams: URLParams.utmParams };
        const apiResponse = await basicApi('init_server_session', data);
        return apiResponse.response as InitServerSessionResponse;
    } catch (e) {
        throw new Error('Failed to fetch client init keys');
    }
}

export async function logToServer(message: string, level: string) {
    await api.postClientSideLogResource({ message, level });
}
