import Contentful from '@content_management/contentful';
import { AppConfiguration } from '@config/configuration.types';
import Auth from '../auth/Auth';
import Mixpanel from '../analytics/Mixpanel';
import { DEFAULT_EXPERIMENT_NAME } from '@config/constants';
import Config from 'src/config/config';
import URLParams from 'src/core/URLHandler';

class Experiment {
    private static _variant: string = '';
    private static _configuration: AppConfiguration | undefined = undefined;
    private static _experimentName: string = '';

    public static async start() {
        Experiment.experimentName = Contentful.experiment?.name || DEFAULT_EXPERIMENT_NAME;
        const variantIndex = Experiment.randomizeVariant();
        Experiment.configuration = await Experiment.getAppConfiguration(variantIndex);
        Experiment.variant = Experiment.configuration?.name || '';
        Auth.setVariant(Experiment.variant);
        Mixpanel.trackExperiment(Experiment.variant);
    }

    public static randomizeVariant() {
        const numOfConfigs = Contentful.getConfigNum();
        if (numOfConfigs === 0) {
            throw new Error('No configurations found');
        }

        const numberValue = parseInt(Math.random().toString(16).slice(2, 10), 16);

        return numberValue % numOfConfigs;
    }

    private static async getAppConfiguration(
        variant: number
    ): Promise<AppConfiguration | undefined> {
        if (
            Contentful.configurations == null ||
            variant < 0 ||
            variant >= Contentful.configurations.length
        ) {
            return undefined;
        }

        if (URLParams.siteConfig) {
            const config = await Contentful.fetchConfiguration(URLParams.siteConfig);
            if (config) {
                return config;
            }
        }

        if (URLParams.configOverride !== undefined) {
            return Contentful.configurations.find(
                (config) => config.name === URLParams.configOverride
            );
        }

        if (!Config.isDevMode || Contentful.testConfig == null) {
            return Contentful.configurations[variant];
        } else {
            return Contentful.testConfig;
        }
    }

    public static get variant() {
        return Experiment._variant;
    }

    public static set variant(variant: string) {
        Experiment._variant = variant;
    }

    public static get configuration() {
        return Experiment._configuration;
    }

    public static set configuration(configuration: AppConfiguration | undefined) {
        Experiment._configuration = configuration;
    }

    public static get experimentName() {
        return Experiment._experimentName;
    }

    private static set experimentName(experimentName: string) {
        Experiment._experimentName = experimentName;
    }
}

export default Experiment;
