import React, { lazy, Suspense } from 'react';
import '../styles/tailwind.scss';
import { isMobile } from '@utils/Utils';
import ThemeProvider from '@providers/ThemeProvider/ThemeProvider';
import ContextProvider from '@providers/ContextProvider/ContextProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Auth from '../auth/Auth';

const UnsupportedDevice = lazy(() => import('@components/UnsupportedDevice/UnsupportedDevice'));
const ScreenTransition = lazy(() => import('./ScreenTransition/ScreenTransition'));

const LoadingFallback = () => (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-200 flex items-center justify-center">
        <div className="text-xl text-gray-800">Loading...</div>
    </div>
);

const App: React.FC = () => {
    if (isMobile()) {
        return (
            <Suspense fallback={<LoadingFallback />}>
                <UnsupportedDevice />
            </Suspense>
        );
    }

    return (
        <ThemeProvider>
            <ContextProvider>
                <GoogleOAuthProvider clientId={Auth.googleClientId}>
                    <ScreenTransition />
                </GoogleOAuthProvider>
            </ContextProvider>
        </ThemeProvider>
    );
};

export default App;
