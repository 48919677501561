import Config from '@config/config';
import Auth from '@auth/Auth';
import Logger from '@logging/logger';

export default class GoogleAnalytics {
    private static initialized = false;
    static init(measurementId: string): void {
        try {
            if (!Config.isDevMode) {
                window.dataLayer = window.dataLayer || [];
                GoogleAnalytics.initialized = true;
            }
        } catch (error) {
            Logger.error('Error in initializing Google Tag manager:', error);
        }
    }

    static identify(): void {
        if (!GoogleAnalytics.initialized) {
            return;
        }

        window.dataLayer.push({
            userId: Auth.getUser().id, // Replace with your user ID value
            event: 'userIdSet',
        });
    }

    static track(name: string, properties?: Record<string, any>): void {
        if (!GoogleAnalytics.initialized) {
            return;
        }

        window.dataLayer.push({ event: name, ...properties });
    }
}
