export enum ResponseTypeEnum {
    FROM_LIST = '0',
    FREE_TEXT = '1',
}

export const HOTJAR_VERSION: number = 6;

export const API_TIMEOUT = 60000; /* msec */
export const API_RETRIES = 3;
export const API_RETRY_DELAY = 500; /* msec */
export const NOT_AVAILABLE = 'N/A';

export const LOGO_SIZE = 20;

export const AVATAR_STORAGE_KEY = 'avatar';
export const FAKE_NAME_PREFIX = '🤖 ';
export const DEFAULT_EXPERIMENT_NAME = 'missing experiment name';
export const CHAT_TIMEOUT = 30 * 60 * 1000; /* msec */
export const LANDING_PROP_PLACEHOLDER_STRING = 'XXX';
export const MICROSOFT_GRAPH_FETCH_TOKEN_URL = 'https://graph.microsoft.com/v1.0';
export const OUTLOOK_MAIL_SUFFIX = '@outlook.com';
export const MICROSOFT_SSO_AUTHORITY = 'https://login.microsoftonline.com/common';

export const CHAT_BOT_AVATAR = process.env.REACT_APP_BOT_AVATAR || '/graphics/icon_square.png';
export const FEATURE_FLAGS_URL_PREFIX = 'ff_';
export const CONFIG_OVERRIDE_URL_KEY = 'config';
export const MESSAGE_TARGET_CHAT = '';

export const AI_AVATAR = '/graphics/Shpere-03.png';

export const UTM_SOURCE = window.location.host;
