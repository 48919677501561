import React from 'react';
import { ConfigProvider } from 'antd';

const primary = '#2b308b';
const white = '#ffffff';
const secondary = '#dee2ff';
const secondaryText = '#4153d9';

const theme = {
    components: {
        Layout: {
            footerBg: 'transparent',
        },
        Button: {
            defaultBg: white,
            defaultActiveBg: secondary,
            defaultHoverBg: secondary,
            defaultColor: secondaryText,
            colorPrimary: primary,
            primaryColor: white,
            borderColorDisabled: 'transparent',
        },
        Table: {
            headerBg: 'transparent',
            headerBorderRadius: 20,
            borderColor: 'transparent',
            cellBorderColor: 'transparent',
        },
    },
    token: {
        colorTextPlaceholder: '#808080',
        colorLink: primary,
    },
};

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <ConfigProvider theme={theme}>{children}</ConfigProvider>
);

export default ThemeProvider;
