import { components } from './generated/contentful-config';
import defaultConfig from '@shared/contentful-config.json';

type ContentfulSchema = components['schemas'];
type EntryIds = ContentfulSchema['EntryIds'];
type TypeIds = ContentfulSchema['TypeIds'];

export class ContentfulConfig {
    private static config: ContentfulSchema['ContentfulConfig'] = defaultConfig;

    static get environment(): string {
        return process.env.REACT_APP_CONTENTFUL_ENV || 'stage';
    }

    static get referenceIncludeDepth(): number {
        return this.config.config.referenceIncludeDepth || 0;
    }

    static getEntryId(key: keyof EntryIds): string {
        return this.config.entryIds[key] || '';
    }

    static getTypeId(key: keyof TypeIds): string {
        return this.config.typeIds[key] || '';
    }
}

export type ContentfulEntryId = keyof EntryIds;
export type ContentfulTypeId = keyof TypeIds;
