import mixpanel from 'mixpanel-browser';
import { AnalyticsEvent, AnalyticsEventName } from './AnalyticsEvent';
import { NOT_AVAILABLE } from '@config/constants';
import Auth, { UserDetails } from '../auth/Auth';
import Logger from '@logging/logger';
import URLParams from '@core/URLHandler';
import Experiment from '../experiments/experiment';

class Mixpanel {
    public static token: string = '';

    static init(token: string): void {
        Mixpanel.token = token;
        try {
            mixpanel.init(token, {
                debug: false,
                track_pageview: true,
                persistence: 'localStorage',
                record_sessions_percent: 100,
            });
        } catch (error) {
            Logger.error('Error in initializing Mixpanel:', error);
        }

        const params = URLParams.utmParams;
        if (!!!params) {
            URLParams.UTM_KEYS.forEach((key) => mixpanel.unregister(key));
        } else {
            URLParams.UTM_KEYS.forEach((key) => {
                if (params[key] !== undefined) {
                    mixpanel.register({ [key]: params[key] });
                }
            });
        }
    }

    static startSession(loadingTime: number): void {
        Mixpanel.identify();
        Mixpanel.register(
            Mixpanel.MixpanelPropertyName.GIT_TAG,
            process.env.REACT_APP_GIT_TAG || NOT_AVAILABLE
        );
        Mixpanel.register(Mixpanel.MixpanelPropertyName.SESSION_ID, Auth.sessionId());

        const baseUrl =
            'https://insights.hotjar.com/sites/5185525/playbacks?filters=%7B%22EQUAL%22:%7B%22user_attributes.str.user_id%22:%22';
        const userId = Auth.getUser().id;
        const HotjarURL = baseUrl + userId + '%22%7D%7D';

        Mixpanel.register(Mixpanel.MixpanelPropertyName.USER_TOKEN, Auth.getUserToken());
        Mixpanel.register(Mixpanel.MixpanelPropertyName.HOTJAR_SESSION_URL, HotjarURL);
        AnalyticsEvent.create(AnalyticsEventName.NOLOS_SESSION_START)
            .set('loading_time', loadingTime)
            .mixpanelTrack();

        Logger.debug('Loading time: ', loadingTime);
        if (!Mixpanel.isIdentified()) {
            Mixpanel.updateUser({
                userToken: Auth.getUserToken(),
                $name: Auth.getUser().name,
                $email: Auth.getUser().$email,
                $avatar: Auth.getUser().photoBase64,
                ssoProvider: Auth.getUser().provider,
            });

            const params = URLParams.utmParams;
            Mixpanel.setUTMParam('$Initial UTM Medium', params.utm_medium);
            Mixpanel.setUTMParam('$Initial UTM Campaign', params.utm_campaign);
            Mixpanel.setUTMParam('$Initial UTM Content', params.utm_content);
            Mixpanel.setUTMParam('$Initial UTM Source', params.utm_source);
            Mixpanel.setUTMParam('$Initial UTM Term', params.utm_term);
            Mixpanel.setUTMParam('$Initial UTM Group', params.utm_group);
            Mixpanel.setUTMParam('$Initial UTM Campaign Id', params.utm_campaignid);
            Mixpanel.setUTMParam('$Initial UTM Ad Group Id', params.utm_adgroupid);
            Mixpanel.setUTMParam('$Initial UTM Loc Physical Ms', params.utm_loc_physical_ms);
            Mixpanel.setUTMParam('$Initial UTM network', params.utm_network);
            Mixpanel.setUTMParam('$Initial UTM device', params.utm_device);
            Mixpanel.setUTMParam('$Initial UTM device model', params.utm_devicemodel);
            Mixpanel.setUTMParam('$Initial UTM keyword', params.utm_keyword);
            Mixpanel.setUTMParam('$Initial UTM gclid', params.utm_gclid);
        }
    }

    static setUTMParam(paramName: string, value: string | undefined): void {
        if (value) {
            Mixpanel.updateUser({ [paramName]: value });
        }
    }

    static isEmail(str: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
    }

    static isIdentified(): boolean {
        return Mixpanel.isEmail(mixpanel.get_distinct_id());
    }

    static identify(id?: string): void {
        if (id && mixpanel.get_distinct_id() === id) {
            return;
        }

        if (id) {
            if (this.isIdentified()) {
                mixpanel.reset();
            }

            mixpanel.identify(Auth.getUser().id);
        } else {
            // For some reason, it fixes a bug in setting user props without it, cant set the props
            mixpanel.identify();
            Auth.setUserId(mixpanel.get_distinct_id());
        }
    }

    static updateUser(properties: Record<string, any>, superProps?: Record<string, any>) {
        mixpanel.people.set(properties);
        if (superProps) {
            mixpanel.register(superProps);
        }
    }

    static getUserProperties(): Record<string, any> {
        return mixpanel.get_distinct_id();
    }

    static track(name: string, properties?: Record<string, any>): void {
        try {
            mixpanel.track(name, properties);
        } catch (error) {
            if (
                error instanceof Error &&
                error.message ===
                    "Cannot read properties of undefined (reading 'disable_all_events')"
            ) {
                Logger.error('Mixpanel is not initialized');
            }
        }
    }

    static register(propName: string, propValue: string): void {
        mixpanel.register({
            [propName]: propValue,
        });
    }

    static handleLogIn(logInId: string, details: UserDetails): void {
        // 1. Store anonymous user properties before identification
        const mpState = JSON.parse(
            localStorage.getItem('mp_' + Mixpanel.token + '_mixpanel') || '{}'
        );
        const anonProperties = mpState.__mps || {}; // People properties
        const superProps = mixpanel.get_property('super') || {};

        let utmProperties = {};
        if (mpState.hasOwnProperty('utm_medium')) {
            utmProperties = { ...utmProperties, '$Initial UTM Medium': mpState.utm_medium };
        }
        if (mpState.hasOwnProperty('utm_campaign')) {
            utmProperties = { ...utmProperties, '$Initial UTM Campaign': mpState.utm_campaign };
        }
        if (mpState.hasOwnProperty('utm_content')) {
            utmProperties = { ...utmProperties, '$Initial UTM Content': mpState.utm_content };
        }
        if (mpState.hasOwnProperty('utm_source')) {
            utmProperties = { ...utmProperties, '$Initial UTM Source': mpState.utm_source };
        }
        if (mpState.hasOwnProperty('utm_term')) {
            utmProperties = { ...utmProperties, '$Initial UTM Term': mpState.utm_term };
        }
        if (mpState.hasOwnProperty('utm_group')) {
            utmProperties = { ...utmProperties, '$Initial UTM Group': mpState.utm_group };
        }

        const properties = {
            ...anonProperties,
            ...utmProperties,
            $name: details.name,
            $email: details.$email,
            $avatar: details.photoBase64,
            ssoProvider: details.provider,
        };
        mixpanel.alias(logInId);
        Mixpanel.identify(logInId);
        this.updateUser(properties, superProps);
    }

    private static async blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = (reader.result as string)
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                resolve(base64String);
            };
            reader.readAsDataURL(blob);
        });
    }

    public static trackExperiment(variant: string) {
        Mixpanel.track('$experiment_started', {
            'Experiment name': Experiment.experimentName,
            'Variant name': variant,
        });
    }
}

namespace Mixpanel {
    export enum MixpanelPropertyName {
        SESSION_ID = 'session_id',
        GIT_TAG = 'git_tag',
        USER_TOKEN = 'user_token',
        HOTJAR_SESSION_URL = 'hotjar_session_url',
    }
}

export default Mixpanel;
