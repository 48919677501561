import Contentful from '@content_management/contentful';
import { ScreenName } from '@providers/ContextProvider/ContextProvider';
import { CachedUTM, Features } from '@config/configuration.types';
import { CONFIG_OVERRIDE_URL_KEY, FEATURE_FLAGS_URL_PREFIX } from '@config/constants';
import { FEATURE_FLAGS } from '@config/features';
import Config from 'src/config/config';

export type URLFeatureFlags = {
    [K in keyof Features]?: boolean;
};

export interface UTMParamsInterface {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_group?: string;
    utm_campaignid?: string;
    utm_adgroupid?: string;
    utm_loc_physical_ms?: string;
    utm_matchtype?: string;
    utm_network?: string;
    utm_device?: string;
    utm_devicemodel?: string;
    utm_keyword?: string;
    utm_gclid?: string;
}

export interface FirstScreenConfig {
    isAutoPrompt: boolean;
    autoPrompt: string;
    initialTerm: string;
    screenName: ScreenName;
}

export class URLHandler {
    private static _firstScreenConfig: FirstScreenConfig | unknown = null;

    public static init(): void {
        let isAutoPrompt = false;
        let initialTerm = '';
        let screenName = ScreenName.UNDEFINED;
        let autoPrompt = '';

        const { hasMatch, matches } = URLParams.findSegmentMatches(URLParams.urlSegments[0]);
        if (hasMatch) {
            autoPrompt = matches[0].script.initialPrompt;
            initialTerm = matches[0].script.initialTerm;
            isAutoPrompt = true;
            screenName = ScreenName.SPLIT;
        } else {
            screenName = ScreenName.HOME;
        }

        URLHandler._firstScreenConfig = {
            isAutoPrompt,
            autoPrompt: autoPrompt.replace(/_/g, ' '),
            initialTerm,
            screenName,
        };
    }

    public static get firstScreenConfig(): FirstScreenConfig {
        if (!URLHandler._firstScreenConfig) {
            URLHandler.init();
        }

        return URLHandler._firstScreenConfig as FirstScreenConfig;
    }
}

class URLParams {
    private static _utmParams: UTMParamsInterface = {};
    private static _featureFlags: URLFeatureFlags = {};
    private static _configOverride: string | undefined = undefined;
    private static hasUtmParams = false;
    public static _userToken: string = '';
    public static _urlSegments: string[] = [];
    public static readonly UTM_KEYS: (keyof UTMParamsInterface)[] = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_group',
        'utm_campaignid',
        'utm_adgroupid',
        'utm_loc_physical_ms',
        'utm_matchtype',
        'utm_network',
        'utm_device',
        'utm_devicemodel',
        'utm_keyword',
        'utm_gclid',
    ];

    public static readonly FEATURE_FLAGS_NAMES = Object.keys(FEATURE_FLAGS) as (keyof Features)[];
    public static siteConfig: string = '';

    public static collectURLParams(strip: boolean = true) {
        const params = new URLSearchParams(window.location.search);
        this._urlSegments = window.location.pathname.split('/').filter((segment) => segment !== '');

        this.UTM_KEYS.forEach((key) => {
            const value = params.get(`${key}`);

            if (value) {
                this._utmParams[`${key}` as keyof UTMParamsInterface] = value;
                this.hasUtmParams = true;
            }

            this.userToken = params.get('token') || '';
        });

        this._featureFlags = Object.fromEntries(
            this.FEATURE_FLAGS_NAMES.map((ff) => {
                const value = params.get(`${FEATURE_FLAGS_URL_PREFIX}${ff}`);
                return [ff, value === null ? undefined : value === 'true'];
            })
        ) as URLFeatureFlags;

        /* Set config override. */
        this._configOverride = params.get(`${CONFIG_OVERRIDE_URL_KEY}`) || undefined;

        // set config
        this.siteConfig = params.get('config') || '';

        // Strip only if URL contain terms.
        if (strip && params.size !== 0 && !Config.isDevMode) {
            let url = '/';

            // Add each feature flag to the URL if it has a defined value
            Object.entries(this._featureFlags).forEach(([flag, value]) => {
                if (value !== undefined) {
                    url = url + `${FEATURE_FLAGS_URL_PREFIX}${flag}=${value}&`;
                }
            });

            if (url !== '/') {
                url = '?' + url.slice(1);
            }

            window.history.replaceState({}, '', url);
        }
    }

    public static clear() {
        this._utmParams = {};
        this.hasUtmParams = false;
    }

    public static get utmParams(): UTMParamsInterface {
        return this.hasUtmParams ? this._utmParams : {};
    }

    public static get userToken(): string {
        return this._userToken;
    }

    public static set userToken(token: string) {
        this._userToken = token;
    }

    public static get urlSegments(): string[] {
        return this._urlSegments;
    }

    public static get featureFlags(): URLFeatureFlags {
        return this._featureFlags;
    }

    public static findSegmentMatches = (
        segment?: string
    ): { hasMatch: boolean; matches: CachedUTM[] } => {
        const matches = Contentful.cachedUTM?.filter(
            (item) => item.utmTerm?.toLowerCase() === segment?.toLowerCase()
        ) as CachedUTM[];

        return {
            hasMatch: matches ? matches.length > 0 : false,
            matches: matches,
        };
    };

    public static get configOverride(): string | undefined {
        return this._configOverride;
    }
}

export default URLParams;
