import Hotjar from '@hotjar/browser';
import { HOTJAR_VERSION } from '@config/constants';
import Config from '../config/config';
import Auth from '../auth/Auth';

class HotjarAnalytics {
    static init(siteId: number) {
        if (!Config.isDevMode) {
            void new Promise(() => Hotjar.init(siteId, HOTJAR_VERSION));
        }
    }

    static identify() {
        if (!Config.isDevMode) {
            const baseUrl =
                'https://mixpanel.com/project/3431132/view/3934712/app/profile#distinct_id=';
            const userId = encodeURIComponent(Auth.getUser().id);
            const mixpanelUrl = baseUrl + userId;
            Hotjar.identify(Auth.getUser().id, {
                name: Auth.getUser().name,
                email: Auth.getUser().$email,
                mixpanel_url: mixpanelUrl,
            });
        }
    }
}

export default HotjarAnalytics;
